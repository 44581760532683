.App {
  background-color: black;
  text-align: center;
  color: white;
  text-align: center;
  font-size: 24px;
  margin: 15vw auto;
}


img{
  filter: invert(100%);
  width: 25vw;
}